import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { DeLabModal, DeLabButton, DeLabConnect } from '@delab-team/connect';
import { QRCodeSVG } from 'qrcode.react';
const DeLabConnector = new DeLabConnect('https://google.com', 'Test', 'mainnet');
export const App = () => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [isConnected, setIsConnected] = React.useState(false);
    const [address, setAddress] = React.useState(undefined);
    const [network, setNetwork] = React.useState('mainnet');
    const [typeConnect, setTypeConnect] = React.useState(undefined);
    const [dataTx, setDataTx] = React.useState(null);
    const [approveLink, setApproveLink] = React.useState('');
    async function sendTransaction() {
        const trans = {
            to: 'EQAgfrO5OwCDzm30rcxC0o49BBhaPPdPQAW8BgEbewPLJhgk',
            value: '100000000',
            text: 'Test pay'
        };
        const dataTx2 = await DeLabConnector.sendTransaction(trans);
        setDataTx(dataTx2);
    }
    function listenDeLab() {
        DeLabConnector.on('connect', (data) => {
            setIsConnected(true);
            const connectConfig = data.data;
            setAddress(connectConfig.address);
            setTypeConnect(connectConfig.typeConnect);
            setNetwork(connectConfig.network);
        });
        DeLabConnector.on('disconnect', () => {
            setIsConnected(false);
            setAddress(undefined);
            setTypeConnect(undefined);
            setNetwork('mainnet');
            console.log('disconect');
        });
        DeLabConnector.on('error', (data) => {
            console.log('error-> ', data.data);
        });
        DeLabConnector.on('error-transaction', (data) => {
            console.log('error-transaction-> ', data.data);
        });
        DeLabConnector.on('error-toncoinwallet', (data) => {
            console.log('error-toncoinwallet-> ', data.data);
        });
        DeLabConnector.on('error-tonhub', (data) => {
            console.log('error-tonhub-> ', data.data);
        });
        DeLabConnector.on('error-tonkeeper', (data) => {
            console.log('error-tonkeeper-> ', data.data);
        });
        DeLabConnector.loadWallet();
    }
    useEffect(() => {
        if (!firstRender && DeLabConnector) {
            setFirstRender(true);
            listenDeLab();
        }
    }, []);
    return (_jsxs("div", { style: {
            height: '100vh',
            background: '#161726',
            color: '#fff',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(https://ipfs.io/ipfs/bafybeiemkofqtjsvywfk53xthpv5dqwmpszrzvcaxf4ftfec6ixll5mi5a)`,
            backgroundSize: `cover`
        }, children: [_jsx("div", { style: { display: 'flex', justifyContent: 'center' }, children: _jsxs("div", { style: {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '650px',
                        backgroundColor: isConnected ? '#00000059' : 'transparent',
                        borderRadius: '20px',
                        padding: '20px',
                        marginTop: '20px',
                        height: '80vh'
                    }, children: [!isConnected ?
                            _jsx(DeLabButton, { DeLabConnectObject: DeLabConnector, scheme: 'dark' })
                            : null, isConnected ? _jsxs("div", { children: [_jsx("h3", { children: "Info block" }), _jsxs("p", { children: ["isConnected: ", isConnected ? 'Connected' : 'Disconnected'] }), _jsxs("p", { children: ["typeConnect: ", typeConnect] }), _jsxs("p", { children: ["network: ", network] }), _jsxs("p", { children: ["address: ", address ? `${address.substr(0, 5)}...${address.substr(address.length - 5, address.length)}` : ``] }), _jsx("button", { onClick: () => DeLabConnector.disconnect(), children: "Disconnect" }), _jsx("h3", { children: "Send transaction" }), _jsx("button", { onClick: () => sendTransaction(), children: "Send test transaction" }), _jsxs("p", { children: ["transaction: ", JSON.stringify(dataTx)] }), approveLink !== '' ?
                                    _jsx("div", { style: { borderRadius: '20px', padding: '20px', background: '#fff', marginBottom: '20px', width: '200px' }, children: _jsx(QRCodeSVG, { value: approveLink, width: 200, height: 200 }) })
                                    : null] }) : null] }) }), _jsx(DeLabModal, { DeLabConnectObject: DeLabConnector, scheme: 'dark' })] }));
};
