import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import eruda from 'eruda';
import ReactDOM from 'react-dom';
import { App } from './App';
const el = document.createElement('div');
document.body.appendChild(el);
eruda.init({
    container: el,
    tool: ['console', 'elements']
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }), document.querySelector('#root'));
